body
{
    overflow-x: hidden;
}

.nav
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 101;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    user-select: none;
    color: white;
}

.nav > a
{
    margin: 0 10px;
    padding: 15px 30px;
    width: 100px;
    transition: background-color 200ms linear, color 100ms linear;
    color: inherit;
    /*cursor: inherit;*/
    text-decoration: inherit;
    text-align: center;
}

.nav > a:hover
{
    background-color: rgba(230, 230, 230, 0.7);
}

.content
{
    position: relative;
}
.content > div
{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
}
.content > div > div
{
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
}


.infoBoxes
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
}
.infoBoxes > div
{
    height: 100%;
    margin: 0 20px;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    box-sizing: border-box;
    padding: 20px 20px;
}


.mobile-columns {
    display: flex;
    flex-direction: column;
}
.mobile-columns-split {
    border-top: 1px solid #000;
}
.mobile-only {
    display: block;
}

@media (min-width: 800px) {
    .mobile-columns {
        flex-direction: row;
    }
    .mobile-columns > * {
        flex-grow: 1;
        flex-basis: 0;
    }
    .mobile-columns-split {
        border-top: 0;
        border-left: 1px solid #000;
    }
    .mobile-only {
        display: none;
    }
}

input, textarea
{
    display: block;
    width: 90%;
    box-sizing: border-box;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    line-height: 1.5;
    overflow: visible;
    border: 1px solid #222;
    border-radius: 0.25rem;
    background-color: #fff;
    background-clip: padding-box;
    font-family: Arial, serif;
}

textarea
{
    resize: none;
    height: 30vh;
}

.btn {
    box-sizing: border-box;
    padding: 10px 40px;
    background-color: white;
    border: 1px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 200ms linear;
}
.btn:hover {
    background-color: rgb(200, 200, 200);
}



.fade-enter, .fade-exit {
    transition: opacity 500ms ease;
}

.fade-enter {
    opacity: 0;
}
.fade-exit {
    opacity: 1;
    z-index: 100;
}

.fade-enter.fade-enter-active {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
}


.experience {
    display: inline-block;
    transition: opacity 500ms ease, transform 500ms ease;
}
.experience-enter {
    opacity: 0;
    transform: translateY(10px);
}
.experience-enter-active {
    opacity: 1;
    transform: none;
}
.experience-exit {
    opacity: 1;
    transform: none;
}
.experience-exit-active {
    opacity: 0;
    transform: translateY(-10px);
}

.bounce {
    transform: none;
    animation: bounce 8s ease 4s infinite;
}
@keyframes bounce {
    0%   {transform:translateY(0px);}
    8%  {transform:translateY(-15px);}
    16% {transform:translateY(0px);}
    24%  {transform:translateY(-15px);}
    32% {transform:translateY(0px);}
    100% {transform:translateY(0px);}
}



::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #000;
}
::-webkit-scrollbar-thumb {
    background: #bbb;
}


/* Container needed to position the overlay. Adjust the width as needed */
.gallery-image {
    position: relative;
    max-width: 300px;
    box-sizing: border-box;
}
.gallery-image > img {
    display: block;
    width: 100%;
    height: auto;
}
.gallery-image > div {
    position: absolute;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1;
    width: 100%;
    transition: .3s ease;
    opacity: 0;
    color: white;
    font-size: 30px;
    padding: 20px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}
.gallery-image:hover > div {
    opacity: 1;
}
